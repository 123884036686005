
export default {
	name: 'sickList',
	data() {
		return {
			sex: '',
			newEdit: false,
			projectName: '', //标题
			crcUserList: '', //列表
			departmentName: '', //科室
			diseaseName: '', //适应症
			startTime: '',
			refStatistics: []
		}
	},
	created() {
		this.token = this.getCookie('token')
		this.type = this.getCookie('type')
		this.getProPar()
		this.getStatistics1()
	},

	methods: {
		toSickParticulars(index, list) {
			this.$router.push({
				name: 'sickParticulars', params: {
					id: this.$route.params.id,
					patientId: list[index].patientId
				}
			})
		},
		getProPar() {
			var _this = this;
			_this.$http.post('/API/PLAT/projectInfo', {
				pid: _this.$route.params.id
			}, {
				headers: {
					"token": _this.token
				}
			})
				.then(function (response) {
					if (response.data.code == 200) {
						//							console.log(response.data.data.crcUserList)
						_this.crcUserList = response.data.data.crcUserList;
						_this.projectName = response.data.data.projectName;
						_this.departmentName = response.data.data.departmentName;
						_this.diseaseName = response.data.data.diseaseName;
						_this.startTime = response.data.data.startTime;
						_this.endTime = response.data.data.endTime;
					}
				})
		},
		getStatistics1() {//进修统计
			var _this = this;
			_this.$http.post('API/PROJECT/checkPatientListByProjectId', {
				pid: _this.$route.params.id,
				isAll: 1
			}, {
				headers: {
					"token": _this.token
				}
			})
				.then(function (response) {
					if (response.data.code == 200) {
						_this.refStatistics = response.data.data.checkPatientList;
					}
				})
		},
		tableRowClassName({
			row,
			rowIndex
		}) {
			if (rowIndex === 1) {
				return "warning-row";
			} else if (rowIndex === 3) {
				return "success-row";
			}
			return "";
		},
		toPl() {
			this.$router.go(-1)
		},
	}
}
